import React from "react"

interface Props {}

export const SpecproektScreen: React.FC<Props> = () => {
  const pClassName = "font-body my-4 text-center text-xl max-w-2xl"
  return (
    <div className="flex-col flex items-center justify-center">
      <h1 className="text-center px-4 font-display text-6xl my-8">
          Спецпроект из Перми: красный мишка исполняет мечты
      </h1>
    </div>
  )
}

export default SpecproektScreen
