import React from "react"
import Entry from "../../types/entry"
import Picture from "../../types/picture"
import Image from "gatsby-image"
import useWindowSize, { breakPoints } from "./useWindowSize"
import { Link } from "gatsby"
import Portrait from "../../types/potrait"
import Story from "../../types/story"
import PortraitComponent from "./elements/Portrait"
import TextContainer from "../components/elements/TextContainer"

interface Props {
  entry: Entry
  picture: Picture
  portrait: Portrait
  story: Story
  urlPrefix: string
}

const GalleryCard: React.FC<Props> = ({ entry, picture, portrait, story, urlPrefix }) => {
  return (
    <>
      <GalleryCardDesktop
        picture={picture}
        entry={entry}
        portrait={portrait}
        story={story}
        urlPrefix={urlPrefix}
      ></GalleryCardDesktop>
      <GalleryCardMobile
        picture={picture}
        entry={entry}
        portrait={portrait}
        story={story}
        urlPrefix={urlPrefix}
      ></GalleryCardMobile>
    </>
  )
}

const GalleryCardMobile: React.FC<Props> = ({
  entry,
  picture,
  portrait,
  story,
  urlPrefix
}) => {
  if (!urlPrefix) {
    urlPrefix = '/entry'
  }
  return (
    <Link
      to={`${urlPrefix}/${entry.url}`}
      className="md:hidden block my-8 md:my-16 py-2 w-full"
    >
      <Image fluid={picture.fluid} className="mb-3" />
      <div className="flex align-center px-3">
        <div className="mr-4 flex items-center justify-center">
          <PortraitComponent portrait={portrait} size="small" />
        </div>

        <div>
          <h2 className="font-bold text-2xl">{entry.author}</h2>
          <div className="text-gray-600">{entry.city}</div>
        </div>
      </div>

      <TextContainer html={story.excerpt}></TextContainer>
    </Link>
  )
}

const GalleryCardDesktop: React.FC<Props> = ({
  entry,
  picture,
  portrait,
  story,
  urlPrefix
}) => {
  if (!urlPrefix) {
    urlPrefix = '/entry'
  }
  const { width } = useWindowSize()
  return (
    <Link
      to={`${urlPrefix}/${entry.url}`}
      style={{ transition: "box-shadow 0.15s", width: `calc(50% - 2rem)` }}
      className="hidden md:flex p-4 sm:m-4 lg:p-8 md-8 rounded-lg hover:shadow-lg flex-col items-center justify-between"
    >
      <Image
        fixed={width >= breakPoints.xl ? picture.fixed450 : picture.fixed300}
        className="mb-3"
      />
      <h2 className="font-bold text-2xl mb-2">{entry.author}</h2>
      <div className="text-gray-600 mb-2">{entry.city}</div>
      <p className="my-4 md:my-4">{story.excerpt}</p>
      <PortraitComponent portrait={portrait} size="small" />
    </Link>
  )
}

export default GalleryCard
