import React from "react"
import Entry from "../../types/entry"
import GalleryCard from "./GalleryCard"

interface Props {
  urlPrefix: string
  entries: Entry[]
}

const Gallery: React.FC<Props> = ({ entries, urlPrefix }) => {
  return (
    <div className="flex flex-col md:flex-row my-4 flex-wrap">
      {entries.map((entry: Entry, index: number) => (
        <GalleryCard
          key={index}
          picture={entry.picture}
          portrait={entry.portrait}
          story={entry.storyNode.childMarkdownRemark}
          entry={entry}
          urlPrefix={urlPrefix}
        />
      ))}
    </div>
  )
}

export default Gallery
